export const environment = {
	production: false,
	webview: true,
	siemens: true,
	amplitude: true,
	baseUrl: 'https://api-stg.vehicle.ford.com/api/wallbox/v1',
	tokenAPIUrl: 'https://api.stg01e.gcp.ford.com/api/token/v2/cat-with-refresh-token',
	appId: '09FFCE6D-82F4-4E3F-A7F1-A01EA438AB5D',
	amplitudeApiKey: '6bcd19d1be975e664aa86ce54c9b2a3c',
	vendorCodes: { 'WEB' : ['WS1', 'WEB', 'AUT', 'SIM'], 'SIEMENS' : ['SWA', 'SIE'] },
	vendorIds: { 'WEB': 1, 'SIE': 24 }
	// include production api base url string as a property here
};


